.btn_blue {
  background: #1976d2 !important;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  background-color: gray;
  border: 2px solid gray;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.font-mulish {
  font-family: 'Mulish', sans-serif;
}

* {
  font-family: 'Montserrat', sans-serif;
}

p {
  font-family: 'Mulish', sans-serif;
}

.shaking-animation {
  animation: horizontalShake infinite 1.5s linear!important;
}

@keyframes horizontalShake {
  3%, 12%, 21%, 30%, 39%, 48%, 57%, 65%, 74%, 83%, 92% { transform: translateX(5px) }
  6%, 15%, 24%, 33%, 42%, 51%, 60%, 68%, 77%, 86%, 95% { transform: translateX(3px) }
  9%, 18%, 27%, 36%, 45%, 54%, 63%, 71%, 80%, 89%, 98% { transform: translateX(-5px) }
}

.btn-primary {
  background-color:#1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 12px 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-disable {
  background-color: gray;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 12px 4px;
  font-size: 16px;
  cursor: auto;
}

.login-form div {
  width: 300px;
}

.register-form div {
  width: 300px;
}

.cancel-status {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgb(219 158 167);
  color: red;
  font-weight: bold;
  cursor: pointer;
}

.request-status {
  color: rgb(2 136 209);
  font-weight: bold;
  cursor: pointer;
}

.progress-status {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgb(255 214 157);
  color: rgb(239 121 11);
  font-weight: bold;
  cursor: pointer;
}

.success-status {
  padding: 5px 10px;
  border-radius: 8px;
  background-color: rgb(196 230 162);
  color: rgb(46 125 78);
  font-weight: bold;
  cursor: pointer;
}

.select-file {
  display: flex;
  justify-content: space-between;
  padding: 1vh 1vw;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 8px;
  height: 8vh;
}

.file-group-box {
  display: flex;
  gap: 5px;
}

.file-info {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.upload-img-icon {
  font-size: 28px!important;
  color: #1976d2;
}

.close-img-box {
  display: flex;
  align-items: center;
}

.close-img-icon {
  color: #1976d2;
  cursor: pointer;
}

.upload-img-box {
  display: flex;
  width: 50px;
  padding: 10px;
  background-color: #ccd8e4;
  border-radius: 4px;
}

.term-check-box, .upload-btn-box {
  padding-top: 0px!important;
}

.user-avata {
  position: relative;
  .edit-btn{
    display: none;
  }
  &:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.5);
    .edit-btn {
      display: block;
      position: absolute;
      color: black;
    }
  }
}

.MuiAutocomplete-popupIndicator {
  position: absolute!important;
  right: 0!important;
}

.MuiAutocomplete-endAdornment {
  .MuiAutocomplete-clearIndicator {
    padding-right: 30px!important;
  }
}

.MuiAutocomplete-clearIndicator{
  position: absolute !important;
  right: 0% !important;
}

.phone-select-group {
  .react-tel-input {
    display: flex;
    background-color: rgba(0, 0, 0, 0.06) !important;
    border-bottom: 1px solid rgb(139 139 139) !important;
    justify-content: start;
    width:100%;
    align-items: center;
    input {
      width: 100% !important;
      display: flex;
      justify-content: end;
      background-color: rgb(240 240 240) !important;
      border: none!important;
      border-radius: 0!important;
      border-top-left-radius: 4px!important;
      border-top-right-radius: 4px !important;
    }
    .special-label {
      display: none!important;
    }
    .flag-dropdown{
      width: 20%;
    }
    @media only screen and (max-width: 899px) {
      input { 
        background-color: rgba(0, 0, 0, 0.06) !important;
      }
      .country-list {
        .country-name {
          color: black!important;
        }
      }
    }
  }
}

.react-tel-input {
  input {
    width: 100%!important;
    height: 45px;
  }
}

.user-profile-bg {
  position: relative;
    .bg-edit-btn {
      position: absolute;
      right: 2vw;
      top: 2vh;
      color: gray;
      cursor: pointer;
      &:hover {
        background-color: white;
        color: black;
      }
    }
}

.filename-string {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.file-download-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
}

.file-admin-download-name {
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 500px;
}

.chatting-group {
  position: relative;
  padding-top: 0.5px;
  .chatting-msg {
    display: inline-block;
    overflow-wrap: break-word;
    line-height: 20px;
  }
  .chatting-right-date {
    position: absolute;
    bottom: -12px;
    right: 0;
    font-size: 11px;
  }
  .chatting-left-date {
    position: absolute;
    bottom: -12px;
    left: 0;
    font-size: 11px;
  }
}

.rdw-editor-main {
  max-height: 350px!important;
  height: 140px!important;
}

@media only screen and (max-width: 900px) {
  .login-container {
    background-image: url("./assets/img/bg-login.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: 0!important;
  }
  .left-bg {
    display: none!important;
  }
  .right-bg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .right-bg *{
    color: white!important;
  }
}

@media only screen and (max-width: 310px) {
  .login-form div {
    width: 250px!important;
  }
  .register-form div {
    width: 250px!important;
  }
}